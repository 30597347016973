<template>
  <div id="app" class="container is-fluid is-fullhd" :style="cssVariables">
    <div class="fonts" v-bind:class="{pause: isPause}">
      <div class="samplefont AppearGXSection">
        <p class="product_title"><span class="product_title-small">砧書体制作所</span><br>オーダーバリアブルフォント</p>
        <div class="f_container">
          <p class="vf f_AppearGX has-text-centered" v-bind:style="{ fontVariationSettings: + '\'APER\'' + fvs_AppearGX__v_APER }">始まる</p>
        </div>
      </div>

      <div class="samplefont SodegoGXSection">
        <div class="f_container">
          <p class="vf f_SodegoGX has-text-centered" v-bind:style="{ fontVariationSettings: + '\'wght\'' + fvs_SodegoGX__v_wght + ',\'LENG\'' + fvs_SodegoGX__v_LENG }">テキストです<br>フォントです</p>
        </div>
      </div>

      <div class="samplefont MinGoGXSection">
        <div class="f_container">
          <p class="vf f_MinGoGX has-text-centered" v-bind:style="{ fontVariationSettings: + '\'CNGE\'' + fvs_MinGoGX__v_CNGE }">選択できる<br>検索にも引っかかる<br>これフォント？<br>おもしろい！</p>
        </div>
      </div>

      <div class="samplefont Copy2GXSection">
        <div class="f_container">
          <p class="vf f_Copy2GX has-text-centered">私のおばあちゃん</p>
        </div>
      </div>

      <div class="samplefont MarkGXSection">
        <div class="f_container">
          <p class="vf f_MarkGX has-text-centered" v-bind:style="{ fontVariationSettings: + '\'MARK\'' + fvs_MarkGX__v_MARK }">お正月</p>
        </div>
        <div class="f_slider">
          <b-field>
            <b-slider rounded size="is-large" v-model="fvs_MarkGX__v_MARK" :min="30" :max="100" :tooltip="false"></b-slider>
          </b-field>
        </div>
      </div>

      <div class="samplefont CopyGXSection">
        <div class="f_container">
          <p class="vf f_CopyGX has-text-centered">月が綺麗ですね。</p>
        </div>
      </div>

      <div class="samplefont NobigoGXSection">
        <div class="f_container">
          <p class="vf f_NobigoGX has-text-centered" v-bind:style="{ fontVariationSettings: + '\'wght\'' + fvs_NobigoGX__v_wght + ',\'LENG\'' + fvs_NobigoGX__v_LENG }">制作受付中</p>
        </div>
      </div>

      <div class="samplefont MoveGXSection">
        <div class="f_container sample7">
          <p class="vf f_MoveGX has-text-centered">砧</p>
          <p class="vf f_iro25SLGX has-text-centered">キヌタフォント<br>バリアブル</p>
        </div>
      </div>

      <div class="samplefont footer">
        <div class="columns is-tablet">
          <div class="column is-3 is-offset-9">
            <p class="footer_qandalink"><a @click="isQandaModalActive=true">Q&amp;A</a></p>
            <p class="footer_maillink"><a href="mailto:kinutaff@moji-sekkei.jp">お問合せ</a></p>
            <p class="footer_logo"><a href="http://www.moji-sekkei.jp/">砧書体制作所</a></p>
          </div>
        </div>
      </div>
    </div>

    <!-- modal -->
    <b-modal :active.sync="isQandaModalActive" full-screen scroll="keep">
      <div class="card canda_container">
        <div class="card-content">
          <div class="content">
            <div class="qanda_header">
              <h2 class="qanda_header_title">砧書体制作所<br>オーダーバリアブルフォント<br>Q&amp;A</h2>
              <p class="qanda_header_logo"><a href="//www.moji-sekkei.jp/" target="_blank"><img src="./assets/logo.png" alt="砧書体制作所"></a></p>
            </div>
            <p>はじめに</p>
            <p>オーダーバリアブルフォントでは、個別にフォントを制作いたします。<br>
発注者様の目的（あるブランドのプロモーションや、ある企業のプロモーションなど）に対して自由にご使用いただけます。<br>
媒体（Web、映像、アプリ組み込み、など）の利用制限はございません。</p>

            <hr>

            <p>これまでの質問をまとめました、お問合せの前にご参照ください。</p>

            <dl class="qanda">
              <dt class="qanda-q">Q1.</dt>
              <dd>例えばどこに使おう？</dd>
              <dt class="qanda-a">A1.</dt>
              <dd>タイトルや見出し・本文中のアクセントなど、注目させたいところに最適です。
ロゴマークから社名・ブランド名、文章中の特定な文字が可変する、など</dd>
            </dl>

            <dl class="qanda">
              <dt class="qanda-q">Q2.</dt>
              <dd>どうやって使うの？</dd>
              <dt class="qanda-a">A2.</dt>
              <dd>フォントデータ（WOFF形式）で納品します。<br>
それをサーバーにアップして、CSSで指定するだけです。<br>
ループで動かすことはもちろん、スクロールやバーで動かすだけでなく端末に応じて太さを変えたり動きを変えることも可能です。<br>
スマホの傾きに応じて動きを制御することもできます。<br>
テキストのままなので検索にも引っかかります。<br>
CSS記述例は<span class="qanda_strong">A7</span>をご覧ください。</dd>
            </dl>

            <dl class="qanda">
              <dt class="qanda-q">Q3.</dt>
              <dd>案件の進め方はどうなるの？</dd>
              <dt class="qanda-a">A3.</dt>
              <dd>以下のような流れになります。<br>
1.打合せ<br>
2.ご発注<br>
3.試作作成・文字のデザイン・動きの決定<br>
4.バリアブルフォント制作・動作確認<br>
5.納品</dd>
            </dl>

            <dl class="qanda">
              <dt class="qanda-q">Q4.</dt>
              <dd>スケジュールと予算は、どれぐらい？</dd>
              <dt class="qanda-a">A4.</dt>
              <dd>日程と金額は応相談、フォントデータは買い切りです。更新費はなく、ずっと使えます。</dd>
              <dt class="qanda-note">※例</dt>
              <dd>本Webサイトのサンプル（弊社フォントを使用）の場合<br>
スケジュール：1週間程度<span class="qanda_small">（バリアブルフォント制作3-4日、表示検証2-3日）</span><br>
費用：120,000円-250,000円程度（目安です）<br>マーク及びイラスト・文字数、動きの程度によって変動します。</dd>
            </dl>

            <dl class="qanda">
              <dt class="qanda-q">Q5.</dt>
              <dd>何文字から作れるの？納品形式は？フォントのライセンスは？</dd>
              <dt class="qanda-a">A5.</dt>
              <dd>1文字から可能です。WOFF形式です。当方のフォントを使うので問題ありません。</dd>
              <dt class="qanda-note">※注</dt>
              <dd>デザイン（マーク及びイラスト・タイプフェイスなど）の持ち込みは可能ですが、<br>
権利関係をクリアしているものに限ります。</dd>
            </dl>

            <dl class="qanda">
              <dt class="qanda-q">Q6.</dt>
              <dd>どんなブラウザで使えるの？</dd>
              <dt class="qanda-a">A6.</dt>
              <dd>以下検証結果です。
                <table class="table qanda_table-caniuse">
                  <thead>
                    <tr>
                      <th class="qanda_table-caniuse_os">OS</th>
                      <th class="qanda_table-caniuse_browser">ブラウザ</th>
                      <th class="qanda_table-caniuse_enabled">表示</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th class="qanda_table-caniuse_os" rowspan="3">Windows 10</th>
                      <td class="qanda_table-caniuse_browser">Edge 44</td>
                      <td class="qanda_table-caniuse_enabled">○</td>
                    </tr>
                    <tr>
                      <td class="qanda_table-caniuse_browser">Chrome 79</td>
                      <td class="qanda_table-caniuse_enabled">○</td>
                    </tr>
                    <tr>
                      <td class="qanda_table-caniuse_browser">Firefox 72</td>
                      <td class="qanda_table-caniuse_enabled">○</td>
                    </tr>
                    <tr>
                      <th class="qanda_table-caniuse_os" rowspan="3">macOS 10.15</th>
                      <td class="qanda_table-caniuse_browser">Safari 13</td>
                      <td class="qanda_table-caniuse_enabled">○</td>
                    </tr>
                    <tr>
                      <td class="qanda_table-caniuse_browser">Chrome 79</td>
                      <td class="qanda_table-caniuse_enabled">○</td>
                    </tr>
                    <tr>
                      <td class="qanda_table-caniuse_browser">Firefox 72</td>
                      <td class="qanda_table-caniuse_enabled">○</td>
                    </tr>
                    <tr>
                      <th class="qanda_table-caniuse_os" rowspan="2">iOS 13</th>
                      <td class="qanda_table-caniuse_browser">Safari 13</td>
                      <td class="qanda_table-caniuse_enabled">○</td>
                    </tr>
                    <tr>
                      <td class="qanda_table-caniuse_browser">WebView (Chrome)</td>
                      <td class="qanda_table-caniuse_enabled">○</td>
                    </tr>
                    <tr>
                      <th class="qanda_table-caniuse_os" rowspan="3">Android</th>
                      <td class="qanda_table-caniuse_browser">Chrome 79</td>
                      <td class="qanda_table-caniuse_enabled">○</td>
                    </tr>
                    <tr>
                      <td class="qanda_table-caniuse_browser">WebView<br><span class="qanda_small">(Android 9 搭載標準ブラウザ)</span></td>
                      <td class="qanda_table-caniuse_enabled">○</td>
                    </tr>
                    <tr>
                      <td class="qanda_table-caniuse_browser">Firefox 68</td>
                      <td class="qanda_table-caniuse_enabled">○</td>
                    </tr>
                    <tr class="qanda_table-caniuse_col-windows10ie11">
                      <th class="qanda_table-caniuse_os" rowspan="1">Windows 10</th>
                      <td class="qanda_table-caniuse_browser">Internet Explorer 11</td>
                      <td class="qanda_table-caniuse_enabled">−</td>
                    </tr>
                    <tr class="qanda_table-caniuse_col-windows10ie11note">
                      <td colspan="3"><span class="qanda_small">IE11はバリアブルフォント非対応のため可変はしませんがWebフォントとしての表示はされます。</span></td>
                    </tr>
                  </tbody>
                </table>
                <p>【VF対応ブラウザ<span class="qanda_info">※</span>】<br>
Chrome for desktop 62以上<br>
Chrome for Android 62以上<br>
Android WebView 62以上<br>
macOS 10.13 以上のSafari 11以上<br>
macOS 10.13 以上のFirefox 62以上<br>
iOS Safari 11以上<br>
Microsoft Edge 17以上</p>
                <hr class="qanda_hr-small">
                <p>【VF対応デスクトップアプリケーション<span class="qanda_info">※</span>】<br>
Adobe Illustrator CC2018 (22.0.0)以上<br>
Adobe Photoshop CC2018 (19.0.0)以上<br>
Adobe InDesign CC2020 (15.0)以上<br>
Sketch 59以上</p>
                <hr class="qanda_hr-small">
              </dd>
              <dt class="qanda-note">※注</dt>
              <dd>各システム・アプリケーションのバリアブルフォント対応状況です。</dd>
              <dt class="qanda-note">※注</dt>
              <dd>制作したフォントの検証は上記のうち検証が必要な環境毎に個別に検証いたします。</dd>
              <dt class="qanda-note">※注</dt>
              <dd>その他アプリ組み込みでもご利用いただけます。詳しくはお問い合わせくださいませ。</dd>
              <dt class="qanda-note">※注</dt>
              <dd>デスクトップアプリケーションでの使用は<span class="qanda_strong">別途契約</span>となります。<br>（TrueType形式で納品）</dd>
            </dl>

            <dl class="qanda">
              <dt class="qanda-q">Q7.</dt>
              <dd>CSSだけで使えるの？</dd>
              <dt class="qanda-a">A7.</dt>
              <dd>はい、利用できます。手順は以下の通りです。<br>
1. CSSの<code>@font-face</code>でWebフォントの定義を行う<br>
2. CSSセレクターへ<code>font-family</code>と<code>font-variation</code>の指定を行う<br>
3. HTMLを記述し描画を確認する
                <hr class="qanda_hr-small">

                <p>可変を使ったアニメーションを行いたい場合は、font-variationの値が変移する<code>@keyframes</code>を作ることで実現できます。</p>

                <hr class="qanda_hr-small">
              </dd>
              <dt class="qanda-note">※</dt>
              <dd class="qanda_info">@keyframesを用いた、アニメーションするバリアブルフォントCSSの利用例</dd>
              <pre>@font-face {
  font-family: 'Appear'; // フォント名の指定
  src: url('./assets/fonts/Appear.woff2') format('woff2'), url('./assets/fonts/Appear.woff') format('woff'); // フォントファイルの指定
}

@keyframes Appear {
  // Appearという名前のアニメーションを定義
  0% { font-variation-settings: 'APER' 30,'wegh' 30 }  // キーフレーム毎の軸名と軸値の指定 (0%, 50%, 100%をキーフレームとする例)
  50% { font-variation-settings: 'APER' 100,'wegh' 100 }
  100% { font-variation-settings: 'APER' 30,'wegh' 30 }
}

.f_APER {
  font-family: 'Appear'; // @font-faceで指定したフォント名の指定
  animation-name: Appear; // @keyframsesの定義の指定
  animation-duration: 7.5s; // 0%から100％に至るまでの時間(s=秒)
  animation-timing-function: ease-in-out; // アニメーションの加速度の指定(ゆっくり始まり中間が速くゆっくり終わる例)
  animation-iteration-count: infinite; // アニメーションのループの回数(0%->100%を無限にループする例)
}</pre>
            </dl>

            <dl class="qanda">
              <dt class="qanda-q">Q8.</dt>
              <dd>JavaScriptから制御できる？</dd>
              <dt class="qanda-a">A8.</dt>
              <dd>はい、利用できます。<br>
バリアブルフォントは、CSSの<code>font-variation</code>プロパティの値により形状が決まりますので、<code>font-variation</code>プロパティをJavaScriptで動的に取り扱うことで、JavaScriptを使って自由に文字のかたちを可変させることが可能です。</dd>
            </dl>

            <hr>

            <p>ご不明な点ありましたら、お気軽にご相談ください。<br>砧書体制作所 <a href="mailto:kinutaff@moji-sekkei.jp">kinutaff@moji-sekkei.jp</a></p>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>
<script>
// import fontsample from './components/fontsample.vue'

export default {
  name: 'app',
  components: {
    // fontsample
  },
  data: function () {
    return {
      scrollY: 0,
      bodyHeight: 0,
      windowHeight: 0,
      scrollRate: 0,

      isPause: false,
      fvs_rootState: 0,

      min: 59,
      sec: 59,
      timerOn: false,
      timerObjSlow: null,
      timerObjFast: null,

      // ここからCSS変数計算用の値(包括するスライダーの値とVF内のn軸分の軸値)
      fvs_AppearGX: 0,
      fvs_AppearGX__v_APER: 30,

      fvs_MarkGX: 0,
      fvs_MarkGX__v_MARK: 30,

      fvs_MinGoGX: 0,
      fvs_MinGoGX__v_CNGE: 30,

      fvs_NobigoGX: 0,
      fvs_NobigoGX__v_wght: 30,
      fvs_NobigoGX__v_LENG: 100,

      fvs_SodegoGX: 0,
      fvs_SodegoGX__v_wght: 30,
      fvs_SodegoGX__v_LENG: 100,

      isQandaModalActive: false,

}
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
    this.handleScroll();
    this.start();

    // 例（10回ループを、1000ミリ秒毎に実行します）
    // this.loopSleep(10, 1000, function(i) {
    //   console.log(i);
    //   if (i == 7) {
    //     // breakと同等
    //     return false;
    //   } else if (i % 2 == 0) {
    //     // continueと同等
    //     return;
    //   }
    //   console.log(i);
    // });

    // todo: 時間で-100から+100の間で値をループさせる
    // this.rootValueLoop(1);

  },
  methods: {

    start: function() {
      let self = this;
      let v = 0;
      this.timerObjSlow = setInterval(function(){
        // self.fvs_rootState は setInterval の引数ms毎に、値を-100から100の間で三角関数に基づき値を行き来する。
        // self.fvs_rootState = (0 + 100*Math.sin(v*0.01*Math.PI));
        // slowLoopValue は setInterval の引数ms毎に、65を中心として変移±35の範囲で変化する。
        // self.fvs_SodegoGX__v_wght = (65 + 35*Math.sin(vSlow*0.01*Math.PI));
        // self.fvs_SodegoGX__v_wght = (0 + 100*Math.sin(v*0.01*Math.PI));

        // slowLoopValue1 は setInterval の引数ms毎に、65を中心として変移±35の範囲で変化する。
        let loopValue35_1 = (65 + 35*Math.sin(v*0.01*Math.PI));
        let loopValue100_8 = (150 + 50*Math.sin(v*0.08*Math.PI));
        let loopValue35_3 = (65 + 35*Math.sin(v*0.03*Math.PI));
        let loopValue100_6 = (150 + 50*Math.sin(v*0.06*Math.PI));
        let loopValue35_5 = (65 + 35*Math.sin(v*0.05*Math.PI));
        // let loopValue35_6 = (65 + 35*Math.sin(v*0.06*Math.PI));
        // let loopValue7 = (65 + 35*Math.sin(v*0.07*Math.PI));
        // let loopValue35_8 = (65 + 35*Math.sin(v*0.08*Math.PI));

        // slowLoopValue2 は setInterval の引数ms毎に、150を中心として変移±50の範囲で変化する。
        // let slowLoopValue2 = (150 + 50*Math.sin(vSlow*0.01*Math.PI));

        self.fvs_AppearGX__v_APER = loopValue35_1;
        self.fvs_SodegoGX__v_wght = loopValue35_3;
        self.fvs_NobigoGX__v_wght = loopValue35_5;

        self.fvs_SodegoGX__v_LENG = loopValue100_6;
        self.fvs_NobigoGX__v_LENG = loopValue100_8;

        // self.fvs_CopyGX__v_WORD = loopValue35_6;

        // self.fvs_Copy2GX__v_WORD = loopValue35_3;

        // self.fvs_iro25SLGX__v_slnt = loopValue35_8;

        // self.fvs_MarkGX__v_MARK = loopValue35_1;

        self.fvs_MinGoGX__v_CNGE = loopValue35_1;

        // self.fvs_MoveGX__v_TRAN = loopValue35_1;


        v++;
        // console.log(this.fvs_rootState);
      },60);
      this.timerOn = true;
    },

    handleScroll() {
      this.scrollY      = window.scrollY;
      this.bodyHeight   = document.body.offsetHeight;
      this.windowHeight = window.innerHeight;
      this.scrollRate   = (this.scrollY + (this.windowHeight / 2)) / this.bodyHeight;
      // (x+a)*(x+a)+b
      // 頂点の座標は(-a, b)
      // this.fvs_SodegoGX__v_LENG = (this.scrollRate-100)*(this.scrollRate-100)+0
      // this.fvs_SodegoGX__v_LENG = (this.scrollRate*350-60)*(this.scrollRate*350-60)+100
      // this.fvs_NobigoGX__v_LENG = (this.scrollRate*350-280)*(this.scrollRate*350-280)+100
      // ((100 * this.scrollRate) * (100 * this.scrollRate) * -1) + 400;

      // スクロールレートと二次関数で値をイーズさせる（鏡餅用）
      this.fvs_MarkGX__v_MARK = ((this.scrollRate*385-200)*(this.scrollRate*380-200)+30)

      // スクロールレートと二次関数で値をイーズさせる（月が綺麗ですね用）
      // this.fvs_CopyGX__v_WORD = ((this.scrollRate*300-200)*(this.scrollRate*300-200)-100) * -1

      // スクロールレートと二次関数で値をイーズさせる（目キョロキョロ用）
      // this.fvs_MoveGX__v_TRAN = ((this.scrollRate*100-80)*(this.scrollRate*100-80)-100) * -1
      // スクロールレートのみで値を直線的に移動させる（目キョロキョロ用）
      // this.fvs_MoveGX__v_TRAN = this.scrollRate * 1500 - 1210;

      // 要素の位置座標を取得(.sample7)
      // let targetElement = document.getElementsByClassName('sample7')[0];
      // let sample7Rect   = targetElement.getBoundingClientRect();
      // let sample7Height = targetElement.offsetHeight;
      // 画面の上端から、要素の上端までの距離
      // this.height__MoveGX = sample7Rect.bottom - sample7Rect.top;
      // this.topDistance__MoveGX = sample7Rect.top;

      // 特定のスクロール率になったときにクラスを除去する
      // if (this.fvs_MoveGX__v_TRAN > 89) {
      //   targetElement.classList.remove('animation--stop');
      // }
    },

    //
    // http://easings.net/#easeInOutQuart
    //  t: current time
    //  b: beginning value
    //  c: change in value
    //  d: duration
    //

    loopSleep: function(_loopLimit, _interval, _mainFunc) {
      let loopLimit = _loopLimit;
      let interval = _interval;
      let mainFunc = _mainFunc;
      let i = 0;
      let loopFunc = function () {
        let result = mainFunc(i);
        if (result === false) {
          // break
          return;
        }
        i = i + 1;
        if (loopLimit === false) {
          setTimeout(loopFunc, interval);
        }
        else if (i < loopLimit) {
          setTimeout(loopFunc, interval);
        }
      }
      loopFunc();
    },

    // doAnimation: function(duration) {

    //   let easeInOutQuart = function(t, b, c, d) {
    //     if ((t /= d / 2) < 1) return c / 2 * t * t * t * t + b;
    //     return -c / 2 * ((t -= 2) * t * t * t - 2) + b;
    //   }
    //   let start    = new Date().getTime();
    //   let from     = 0;
    //   let to       = 100;
    //   let timer    = setInterval(function() {
    //     let time = new Date().getTime() - start;
    //     this.fvs_rootState = easeInOutQuart(time, from, to - from, duration);
    //     if (time >= duration) clearInterval(timer);
    //     console.log('a');
    //   }, 1000 / 60);
    //   this.fvs_rootState = from;
    // },

    // rootValueLoop: function(duration) {
    //   let v = 0;
    //   setInterval(function(){
    //     this.fvs_rootState = (0 + 100*Math.sin(v*0.01*Math.PI));
    //     v++;
    //     console.log(this.fvs_rootState);
    //   },duration);
    // },

    // this.loopSleep(10, 1000, function(i) {
    //   console.log(i);
    //   if (i == 7) {
    //     // breakと同等
    //     return false;
    //   } else if (i % 2 == 0) {
    //     // continueと同等
    //     return;
    //   }
    //   console.log(i);
    // });

// マウントする
// ルート値が0
// 関数が実行される
// 関数でルート値が徐々に100になる
// ルート値が100になったらルート値が徐々に0になる
// コンピューテッドでルート値からCSS varが動的に変わる



},
  computed: {
    cssVariables () {
      return {
        '--fvs_AppearGX__v_APER': this.fvs_AppearGX__v_APER,
        '--fvs_MarkGX__v_MARK': this.fvs_MarkGX__v_MARK,
        '--fvs_MinGoGX__v_CNGE': this.fvs_MinGoGX__v_CNGE,
        '--fvs_NobigoGX__v_wght': this.fvs_NobigoGX__v_wght,
        '--fvs_NobigoGX__v_LENG': this.fvs_NobigoGX__v_LENG,
        '--fvs_SodegoGX__v_wght': this.fvs_SodegoGX__v_wght,
        '--fvs_SodegoGX__v_LENG': this.fvs_SodegoGX__v_LENG,
      }
    }
  }
}
</script>

<style lang="scss">

// はじめにblumaのユーティリティをすべて読みこむ
@import "~bulma/sass/utilities/_all";

// 次に色を設定する

$black: #000;
$text: $black;
$body-line-height: 1;

$primary: #000;
$primary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);

$footer-background-color: transparent;
$footer-padding: 0;

// 色を反映させるために色の変数を上書きする
$colors: (
  "white": ($white, $black),
  "black": ($black, $white),
  "light": ($light, $light-invert),
  "dark": ($dark, $dark-invert),
  "primary": ($primary, $primary-invert),
  "info": ($info, $info-invert),
  "success": ($success, $success-invert),
  "warning": ($warning, $warning-invert),
  "danger": ($danger, $danger-invert),
  "twitter": ($twitter, $twitter-invert)
);

// リンクの色を定義する例
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// 最後にbulmaとbuefyをインポートして各セレクタへ適用する
@import "~bulma";
@import "~buefy/src/scss/buefy";

// 何故かis-fluidで1024px未満の時bulmaと挙動が違うので追加
@media screen and (max-width: 1023px) {
  .container.is-fluid {
      margin-left: 5vw;
      margin-right: 5vw;
      max-width: none;
  }
}

.modal-background {
  background-color:  rgba(255, 255, 255, 1);
}

// モーダル関連スタイル
.modal-close,
.delete,
.modal-close,
.delete {
  background-color:  rgba(0, 0, 0, 0.2);
}

.modal {
  font-size: 19px;
  .card-header-title {
    color: rgb(0,0,0);
    font-weight: normal;
  }
  .card-content {
    margin: 0 auto;
    max-width: 960px;
    padding: 40px 50px 100px;
    @include until($desktop) {
      padding: 1.5rem;
    }
    .content {
      .qanda_header {
        display:flex;
        flex-wrap: wrap;
        margin-bottom: 80px;
        .qanda_header_title {
          font-size: 19px;
          line-height: 1.65;
          font-weight: normal;
          color: rgb(0,0,0);
          margin: 12px 0;
          @include until($desktop) {
            font-size: 16px;
            margin: 19px 0;
          }

          width: calc(100% - 85px);
          padding: 0;
          box-sizing: border-box;

        }
        .qanda_header_logo {
          width: 85px;
        }
      }
      p {
        margin: 0;
        line-height: 1.65;
      }
      hr {
        background-color: transparent;
        height: 0;
        margin: 2em 0;
        &.qanda_hr-small {
          margin: 0.5em 0;
        }
      }
      .qanda {
        margin-top: 2em;
        display:flex;
        flex-wrap: wrap;
        // border: 1px solid #ccc;
        // border-top: none;
        @include until($desktop) {
          flex-flow: column nowrap;
        }
        dt {
          font-weight: normal;
          line-height: 1.65;
          // background: #eee;
          width: 60px;
          padding: 0;
          box-sizing: border-box;
          // border-top: 1px solid #ccc;
          &.qanda-q {
            color: #00aee5;
          }
          &.qanda-a {
            color: #ea1821;
          }
          &.qanda-note {
            color: #1aa842;
          }
          @include until($desktop) {
            width: 100%;
          }
        }
        dd {
          line-height: 1.65;
          padding: 0;
          margin: 0;
          // border-left: 1px solid #ccc;
          // border-top: 1px solid #ccc;
          width: calc(100% - 60px);
          // background: #fff;
          box-sizing: border-box;
          @include until($desktop) {
            border-left: none;
            width: 100%;
          }
        }
      }
      .qanda_strong {
        color: #ea1821;
      }
      .qanda_info {
        color: #1aa842;
      }
      .qanda_small {
        display: inline-block;
        position: relative;
        font-size: 15px;
        top: -2px;
      }
      .qanda_table-caniuse {
        color: rgba(0,0,0);
        thead {
          th {
            border-width: 0 0 1px;
          }
        }
        th, td {
          border-color: rgb(148, 148, 148);
        }
        th {
          font-weight: normal;
        }
        .qanda_table-caniuse_os {
          width: 35%;
        }
        .qanda_table-caniuse_browser {
          width: 45%;
        }
        .qanda_table-caniuse_enabled {
          width: 20%;
        }
        // .qanda_table-caniuse_note {
        //   width: 35%;
        // }
        .qanda_table-caniuse_col-windows10ie11 {
          th,
          td {
            border-width: 0;
            padding-bottom: 0;
          }
        }
        .qanda_table-caniuse_col-windows10ie11note {
          th,
          td {
            padding-top: 0;
            text-align: right;
          }
        }
      }
      pre {
        font-size: 14px;
      }
    }
  }
}


html,
body {
  // font-family: defaultfont; // @font-face
  font-family: 'A+4vJx-iroha 26tubaki StdN R', 'NoChattering'; // REALTYPE
}

.container {
  .columns {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    .column {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

.title {
  // 一番上の見出し
}

.lede {
  margin-top: 22vw;
  font-size: 5vw;
  padding-left: 1em;
  // タイトルより小さい文章共通スタイル
}

.f_AppearGX {
  font-variation-settings: 'APER' var(--fvs_AppearGX__v_APER);

  margin-top: 9vw;
  font-size: 15vw;

  @include until($tablet) {
    font-size: 20vw;
  }
}

.f_SodegoGX {
  font-variation-settings: 'wght' var(--fvs_SodegoGX__v_wght), 'LENG' var(--fvs_SodegoGX__v_LENG);
  // font-variation-settings: 'wght' 80, 'LENG' 115;

  // margin-top: 8.8vw;
  font-size: 7vw;
  line-height: 1.65;

  @include until($tablet) {
    font-size: 12vw;
  }

  // padding-left: 1.4em; //
}

.f_MarkGX {
  font-variation-settings: 'MARK' var(--fvs_MarkGX__v_MARK);
  // font-variation-settings: 'MARK' 100;

  // margin-top: 10.5vw;
  font-size: 16vw;

  // padding-left: 0.45em; //

  margin-bottom: 11.5vw;

  @include until($tablet) {
    font-size: 25vw;
  }

}

.f_MinGoGX {
  font-variation-settings: 'CNGE' var(--fvs_MinGoGX__v_CNGE);
  // font-variation-settings: 'CNGE' 30;

  // margin-top: 15.5vw;
  font-size: 5.8vw;
  line-height: 1.75em;

  // padding-left: 1.33em; //

  // margin-bottom: 2.5em;

  @include until($tablet) {
    font-size: 9vw;
  }
}

@keyframes kf_CopyGX {
  // 0% {
  //   font-variation-settings: "WORD" 30.0;
  // }
  // 50% {
  //   font-variation-settings: "WORD" 100.0;
  // }
  // 100% {
  //   font-variation-settings: "WORD" 30.0;
  // }

  0% {
    font-variation-settings: "WORD" 30.0;
  }
  5% {
    font-variation-settings: "WORD" 30.0;
  }
  // 10% {
  //   font-variation-settings: "WORD" 45.0;
  // }
  47% {
    font-variation-settings: "WORD" 85.0;
  }
  50% {
    font-variation-settings: "WORD" 100.0;
  }
  53% {
    font-variation-settings: "WORD" 85.0;
  }
  // 90% {
  //   font-variation-settings: "WORD" 45.0;
  // }
  95% {
    font-variation-settings: "WORD" 30.0;
  }
  100% {
    font-variation-settings: "WORD" 30.0;
  }
}

.f_CopyGX {
  animation-name: kf_CopyGX;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  // animation-timing-function: cubic-bezier(1,0,0,1);
  animation-iteration-count: infinite;

  // font-variation-settings: 'WORD' var(--fvs_CopyGX__v_WORD);
  // font-variation-settings: 'WORD' 100;

  // margin-top: 13.1vw;
  font-size: 7vw;
  line-height: 1.75;

  // padding-left: 0.5em; //

  margin-bottom: 8vw;

  @include until($tablet) {
    font-size: 10vw;
  }
}

@keyframes kf_Copy2GX {
  0% {
    font-variation-settings: "WORD" 30.0;
  }/* 15 */
  15% {
    font-variation-settings: "WORD" 80.0;
  }/* 7 */
  22% {
    font-variation-settings: "WORD" 55.0;
  }/* 7 */
  29% {
    font-variation-settings: "WORD" 80.0;
  }/* 6 */
  35% {
    font-variation-settings: "WORD" 60.0;
  }
  38% {
    font-variation-settings: "WORD" 100.0;
  }/* 12 */
  50% {
    font-variation-settings: "WORD" 100.0;
  }/* 15 */
  65% {
    font-variation-settings: "WORD" 60.0;
  }/* 7 */
  72% {
    font-variation-settings: "WORD" 80.0;
  }/* 7 */
  79% {
    font-variation-settings: "WORD" 50.0;
  }/* 6 */
  85% {
    font-variation-settings: "WORD" 70.0;
  }
  88% {
    font-variation-settings: "WORD" 30.0;
  }
  100% {
    font-variation-settings: "WORD" 30.0;
  }
}

.f_Copy2GX {
  animation-name: kf_Copy2GX;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  font-size: 6.6vw;
  line-height: 1.7;

  // padding-left: 1.5em; //

  margin-bottom: 12vw;

  @include until($tablet) {
    font-size: 10vw;
  }
}

.f_KirikotestCOLR {
  margin-top: 10.5vw;
  font-size: 6.575vw;
  line-height: 1.25em;

  // padding-left: 1.45em; //

  margin-bottom: 2em;
}

.f_NobigoGX {
  font-variation-settings: 'wght' var(--fvs_NobigoGX__v_wght), 'LENG' var(--fvs_NobigoGX__v_LENG);
  // font-variation-settings: 'wght' 75, 'LENG' 150;

  // margin-top: 15vw;
  font-size: 10vw;
  line-height: 1.75;

  // padding-left: 1.1em; //

  // margin-bottom: 1em;

  @include until($tablet) {
    font-size: 12vw;
  }

}

@keyframes kf_MoveGX {
  0% {
      font-variation-settings: "TRAN" 30.0;
  }
  20% {
      font-variation-settings: "TRAN" 90.0;
  }
  30% {
      font-variation-settings: "TRAN" 100.0;
  }
  40% {
      font-variation-settings: "TRAN" 90.0;
  }
  50% {
      font-variation-settings: "TRAN" 100.0;
  }
  60% {
      font-variation-settings: "TRAN" 90.0;
  }
  70% {
      font-variation-settings: "TRAN" 100.0;
  }
  80% {
      font-variation-settings: "TRAN" 90.0;
  }
  100% {
      font-variation-settings: "TRAN" 30.0;
  }
}

.f_MoveGX {
  animation-name: kf_MoveGX;
  animation-duration: 8s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  font-size: 24vw;
  margin-bottom: 2vw;
  @include until($tablet) {
    font-size: 50vw;
  }
}

@keyframes kf_iro25SLGX {
  0% {
      font-variation-settings: "slnt" 100.0;
  }
  20% {
      font-variation-settings: "slnt" 100.0;
  }
  30% {
      font-variation-settings: "slnt" 30.0;
  }
  40% {
      font-variation-settings: "slnt" 100.0;
  }
  50% {
      font-variation-settings: "slnt" 30.0;
  }
  60% {
      font-variation-settings: "slnt" 100.0;
  }
  70% {
      font-variation-settings: "slnt" 30.0;
  }
  80% {
      font-variation-settings: "slnt" 100.0;
  }
  100% {
      font-variation-settings: "slnt" 100.0;
  }
}

.f_iro25SLGX {
  animation-name: kf_iro25SLGX;
  animation-duration: 8s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  font-size: 3.85vw;
  line-height: 1.6;
  @include until($tablet) {
    font-size: 7.7vw;
  }
}

.product_title {
  margin-top: 4vw;
  margin-left: 6vw;
  font-size: 2.2vw;
  .product_title-small {
    font-size: 2vw;
  }
  // padding-top: 0.35em;
  // padding-left: 0.4em; //
  line-height: 1.75;
  @include until($tablet) {
    font-size: 4vw;
    .product_title-small {
      font-size: 3.6vw;
    }
  }

}

.f_description {
  margin-bottom: 2em;
  margin-top: 0.7vw;
  font-size: 1.75vw;
  padding-left: 1.5em;
  padding-top: 0em;
  line-height: 1.5;
}

.samplefont .f_container {
  // サンプルテキスト共通スタイル
}

.samplefont .f_description {
  // フォント毎の説明
}

.samplefont .f_slider {
  width: 21vw;
  margin-left: calc(100% - 25vw);
  padding-right: 4vw;
}

.footer.samplefont {
  padding: 0;
  margin: 0;

  @keyframes qandalink_to_text {
    0% { font-variation-settings: "QNDA" 100 }
    to { font-variation-settings: "QNDA" 30 }
  }
  @keyframes qandalink_to_mark {
    0% { font-variation-settings: "QNDA" 30 }
    to { font-variation-settings: "QNDA" 100 }
  }
  @keyframes maillink_to_text {
    0% { font-variation-settings: "MAIL" 100 }
    to { font-variation-settings: "MAIL" 30 }
  }
  @keyframes maillink_to_mark {
    0% { font-variation-settings: "MAIL" 30 }
    to { font-variation-settings: "MAIL" 100 }
  }
  @keyframes logolink_to_text {
    0% { font-variation-settings: "COME" 100 }
    to { font-variation-settings: "COME" 30 }
  }
  @keyframes logolink_to_mark {
    0% { font-variation-settings: "COME" 30 }
    to { font-variation-settings: "COME" 100 }
  }
  .footer_qandalink {
    font-size: 3.1vw;
    @include until($tablet) {
      font-size: 3.8vw;
    }
  }
  .footer_maillink {
    margin-top: 3.4vw;
    font-size: 2.5vw;
    @include until($tablet) {
      font-size: 3.6vw;
    }
  }
  .footer_logo {
    margin-top: 3.7vw;
    font-size: 2.3vw;
    @include until($tablet) {
      font-size: 3.6vw;
    }
  }
}

.samplefont.omake {
  .f_container {
    padding-bottom: 8vw;
    padding-left: 3.5vw;
    @include from($tablet) {
      padding-left: 20vw;
    }
  }

  .omake_title {
    font-size: 3.5vw;
    padding: 1.5em 3.5vw;
  }
  .omake_colorfont_1 {
    font-size: 6.2vw;
    @include until($tablet) {
      font-size: 9vw;
    }
    line-height: 1.5;
  }
  .omake_colorfont_2 {
    font-size: 6.2vw;
    @include until($tablet) {
      font-size: 8vw;
    }
    line-height: 1.5;
  }
  .omake_colorfont_3 {
    font-size: 8.2vw;
    @include until($tablet) {
      font-size: 10vw;
    }
    line-height: 1.5;
  }
}

// .controller {
//   position: fixed;
//   left: 0;
//   bottom: 0;
//   text-align: right;
//   // padding: 0.5em;
//   // width: 100vw;
// }

.fonts {
  // margin-bottom: 3em;
  .samplefont {
    margin-bottom: 10vw;
    &.SodegoGXSection {
      margin-bottom: 24vw;
    }
    &.MinGoGXSection {
      margin-bottom: 25vw;
    }
    &.Copy2GXSection {
      margin-bottom: 27vw;
    }
    &.MarkGXSection {
      margin-bottom: 23vw
    }
    &.CopyGXSection {
      margin-bottom: 28vw;
    }
    &.NobigoGXSection {
      margin-bottom: 24vw;
    }
    &.MoveGXSection {
      margin-bottom: 18vw;
    }
    &.footer {
      margin-bottom: 15vw;
    }
    .f_slider {
      .b-slider {
        .b-slider-thumb-wrapper .b-slider-thumb {
          background-color: #939598;
          border: none;
          height: 40px;
          width: 40px;
          @include until($desktop) {
            height: 20px;
            width: 20px;
          }
        }
        .b-slider-track {
          height: 1px;
        }
      }
    }
  }
}
.vf {
}


@mixin fontface($font_name: null, $font_path: null) {
  @font-face {
    font-family: quote($font_name);
    src: url($font_path);
    font-display: block;
  }
  .f_#{$font_name} {
    font-family: $font_name;
  }
}

@include fontface(AppearGX, './assets/fonts/Kinuta-AppearGX.ttf');
@include fontface(Copy2GX, './assets/fonts/Kinuta-Copy2GX.ttf');
@include fontface(CopyGX, './assets/fonts/Kinuta-CopyGX.ttf');
@include fontface(iro25SLGX, './assets/fonts/Kinuta-iro25SLGX.ttf');
@include fontface(MarkGX, './assets/fonts/Kinuta-MarkGX.ttf');
@include fontface(MinGoGX, './assets/fonts/Kinuta-Min-GoGX.ttf');
@include fontface(MoveGX, './assets/fonts/Kinuta-MoveGX.ttf');
@include fontface(NobigoGX, './assets/fonts/Kinuta-NobigoGX.ttf');
@include fontface(SodegoGX, './assets/fonts/Kinuta-SodegoGX.ttf');
// @include fontface(KirikoCOLR, './assets/fonts/Kinuta-KirikoCOLR-R.otf');
// @include fontface(irohaCOLR, './assets/fonts/Kinuta-irohaCOLR-R.otf');

// @include fontface(KirikoQAGX, './assets/fonts/Kinuta-QAGX.ttf');
// @include fontface(KirikoMailGX, './assets/fonts/Kinuta-MailGX.ttf');
// @include fontface(KirikoROGOGX, './assets/fonts/Kinuta-ROGOGX.ttf');


$AppearGX_data: (
  '"APER" 30.0, "wegh" 30.0',
  '"APER" 100.0, "wegh" 100.0',
);
$MarkGX_data: (
  '"MARK" 30.0',
  '"MARK" 100.0',
);
$MinGoGX_data: (
  '"CNGE" 30.0',
  '"CNGE" 100.0',
);
$NobigoGX_data: (
  '"wght" 30.0, "LENG" 100.0',
  '"wght" 100.0, "LENG" 200.0',
);
$SodegoGX_data: (
  '"wght" 30.0, "LRNG" 100.0',
  '"wght" 100.0, "LRNG" 200.0',
);

// @font-face {
//   font-family:'defaultfont';
//   font-style: normal;
//   font-weight: 400;
//   font-display: block;
//   src: url('./assets/fonts/iroha26tsubaki.woff2') format('woff2'), url('./assets/fonts/iroha26tsubaki.woff') format('wof');
// }

@keyframes AppearGX {
    0% { font-variation-settings: "APER" 30,"wegh" 30 }
    50% { font-variation-settings: "APER" 100,"wegh" 100 }
    to { font-variation-settings: "APER" 30,"wegh" 30 }
}
.f_APER {
  animation-name: AppearGX;

  animation-duration: 7.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  font-size: 3em;
  // white-space: nowrap;
  // .pause & {
  //   animation-play-state: paused;
  // }
}


</style>
